<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="4" md="4" sm="12" xs="12">
                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    id="fiscal_year_id"
                                    plain
                                    v-model="formData.fiscal_year_id"
                                    :options="fiscalYearList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                            <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="season_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('dae_config.season_name') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    id="season_id"
                                    plain
                                    v-model="formData.season_id"
                                    :options="seasonList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="circular_type"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('dae_config.circular_type') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.circular_type"
                                  :options="subsidyCircularTypeList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  id="fiscal_year_id"
                                  >
                                  <template v-slot:first>
                                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Project Name" vid="project_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="project_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('bsri_config.project_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                id="project_id"
                                plain
                                v-model="formData.project_id"
                                :options="projectList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                            <ValidationProvider name="Memo No" vid="memo_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="memo_no"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('barc_config.memo_no') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="memo_no"
                                    plain
                                    v-model="formData.memo_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Circular Name (n)" vid="circular_name" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="circular_name"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('barc_config.circular_name_en') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="circular_name"
                                plain
                                v-model="formData.circular_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Circular Name (Bn)" vid="circular_name_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="circular_name_bn"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('barc_config.circular_name_bn') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="circular_name_bn"
                                plain
                                v-model="formData.circular_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <template v-if="totFarmerSHow">
                          <b-col lg="4" md="4" sm="12" xs="12">
                            <ValidationProvider name="Total Farmer" vid="total_farmer" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="total_farmer"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('bsri_demandDIS.total_farmer') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="total_farmer"
                                    plain
                                    v-model="formData.total_farmer"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </template>
                        <template v-if="formData.circular_type === 4">
                          <b-col lg="4" md="4" sm="12" xs="12">
                            <ValidationProvider name="Amount (Tk/Per Farmer)" vid="amount_per_farmer" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="amount_per_farmer"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{ $t('dae_config.amount_tk_per_farmer') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="amount_per_farmer"
                                    plain
                                    v-model="formData.amount_per_farmer"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </template>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Amount" vid="amount" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="amount"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('globalTrans.amount') + ' ' + $t('globalTrans.tk')}}<span class="text-danger">*</span>
                            </template>
                            <b-form-input class="form-control"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              v-model="formData.amount"
                              :readonly="formData.circular_type === 4"
                              id="amount"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Application Deadline" vid="deadline" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="deadline"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('dae_subsidy.application_deadline')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input class="form-control"
                              v-model="formData.application_deadline"
                              placeholder="Select Date"
                              id="datepicker"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="8" md="8" sm="12" xs="12">
                          <ValidationProvider name="Description" vid="description">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="description"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.description')}}
                              </template>
                                  <b-form-textarea
                                    id="description"
                                    v-model="formData.description"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="12" xs="12">
                          <ValidationProvider name="Attachment" vid="attachment">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  :label="$t('circular.attachment')"
                                  label-for="attachment"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('globalTrans.attachment') }}
                                  <!-- <slot v-if="formData.attachment">
                                      <a :href="incentiveGrantServiceBaseUrl + formData.attachment" title="Attachment" class="mr-3" download=""><i class="ri-download-cloud-fill"></i></a>
                                  </slot> -->
                              </template>
                                  <b-form-file
                                  id="attachment"
                                  v-on:change="handleFile"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-overlay v-if="isCashAmountShow">
                        <template>
                          <div style="font-size:14px; background-color: #66CC99; padding:6px">
                              <h5 class="text-white text-center"> {{ $t('dae_config.cash_allocation') }}</h5>
                          </div>
                        </template>
                        <b-row>
                          <b-col lg="4" md="4" sm="12" xs="12">
                            <ValidationProvider name="Cash Amount" vid="is_cash_amount">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="1"
                                  label-for="is_cash_amount"
                                  slot-scope="{ errors }"
                              >
                                  <div>
                                      <b-form-checkbox
                                          class="mt-4"
                                          id="is_cash_amount"
                                          v-model="formData.is_cash_amount"
                                          :value="true"
                                          :unchecked-value="false"
                                      >
                                          {{$t('dae_config.cash_amount')}}
                                      </b-form-checkbox>
                                  </div>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </b-overlay>
                      <b-overlay v-if="cropShow">
                        <template>
                          <div style="font-size:14px; background-color: #66CC99; padding:6px">
                              <h5 class="text-white text-center"> {{ $t('dae_config.crop_allocation') }}</h5>
                          </div>
                        </template>
                        <b-row v-for="(detail, index) in formData.crop_details" :key="index" class="mt-2">
                          <b-col lg="4" md="4" sm="12" xs="12">
                              <ValidationProvider name="Crop Name" vid="`crop_id$(index)`" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="`crop_id$(index)`"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('dae_config.crop_name') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      id="crop_id"
                                      plain
                                      v-model="detail.crop_id"
                                      :options="cropNameList"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xl="4" lg="4" md="4" sm="4" style="margin-top:3%">
                            <b-button v-show="index == formData.crop_details.length-1" variant=" iq-bg-success" size="sm" @click="addRoll()"><i class="ri-add-line m-0"></i></b-button>
                            &nbsp;
                            <b-button v-show="index || ( !index && formData.crop_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </b-col>
                        </b-row>
                      </b-overlay>
                      <b-overlay v-if="matShow">
                        <template>
                          <div style="font-size:14px; background-color: #66CC99; padding:6px" class="mt-4">
                              <h5 class="text-white text-center"> {{ $t('dae_config.mat_allo') }}</h5>
                          </div>
                        </template>
                        <b-row v-for="(info, index1) in formData.material_details" :key="index1" class="mt-3">
                          <b-col lg="3" md="3" sm="12" xs="12">
                              <ValidationProvider name="Agriculture Material Type" vid="`material_type_id$(index)`" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="`material_type_id$(index)`"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('dae_config.material_type') }}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      id="material_type_id$(index)"
                                      plain
                                      v-model="info.material_type_id"
                                      :options="agMaterialTypeList"
                                      @change="getMaterialList(index1)"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="3" md="3" sm="12" xs="12">
                              <ValidationProvider name="Agricultural Material Name" vid="`material_id$(index)`" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="`material_id$(index)`"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('dae_config.mat_name') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      id="`material_id$(index)`"
                                      plain
                                      v-model="info.material_id"
                                      :options="info.materialList"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xl="3" lg="3" md="3" sm="12" style="margin-top:3%">
                            <b-button v-show="index1 == formData.material_details.length-1" variant=" iq-bg-success" size="sm" @click="addMat()"><i class="ri-add-line m-0"></i></b-button>
                            <b-button v-show="index1 || ( !index1 && formData.material_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="removeMat(index1)"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </b-col>
                        </b-row>
                      </b-overlay>
                      <b-overlay v-if="instrumentShow">
                        <template>
                          <div style="font-size:14px; background-color: #66CC99; padding:6px" class="mt-4">
                              <h5 class="text-white text-center"> {{ $t('dae_config.instrument_info') }}</h5>
                          </div>
                        </template>
                        <b-row v-for="(info, index2) in formData.instrument_details" :key="index2" class="mt-3">
                          <b-col lg="3" md="3" sm="12" xs="12">
                              <ValidationProvider name="Instrument" vid="`instrument_id$(index)`" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="`instrument_id$(index)`"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{ $t('dae_config.instrument_name') }}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      id="instrument_id$(index)"
                                      plain
                                      v-model="info.instrument_id"
                                      :options="instrumentList"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xl="3" lg="3" md="3" sm="12" style="margin-top:3%">
                            <b-button v-show="index2 == formData.instrument_details.length-1" variant=" iq-bg-success" size="sm" @click="addInstrumnt()"><i class="ri-add-line m-0"></i>{{$t('dae_config.add_more')}}</b-button>
                            <b-button v-show="index2 || ( !index2 && formData.instrument_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="removeInstrumnt(index2)"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </b-col>
                        </b-row>
                      </b-overlay>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
        <!-- <pre>{{$store.state.incentiveGrant.commonObj.projectList}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { circularInfoStore, circularInfoUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getCircularData()
      this.temp_is_cash_amount = tmp.is_cash_amount
      this.temp_circular_type = tmp.circular_type
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  data () {
    return {
      loading: false,
      cropShow: false,
      matShow: false,
      instrumentShow: false,
      totFarmerSHow: false,
      isCashAmountShow: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
      temp_is_cash_amount: false,
      temp_circular_type: 0,
      projectList: [],
      formData: {
        fiscal_year_id: 0,
        project_id: 0,
        season_id: 0,
        circular_id: 0,
        attachment: null,
        edit_attachment: null,
        circular_type: 0,
        is_cash_amount: false,
        amount: 0,
        total_farmer: '',
        amount_per_farmer: '',
        application_deadline: '',
        description: '',
        crop_details: [
          {
            crop_id: 0
          }
        ],
        material_details: [
          {
            material_type_id: 0,
            material_id: 0,
            materialList: []
          }
        ],
        instrument_details: [
          {
            instrument_id: 0
          }
        ]
      }
    }
  },
  computed: {
    subsidyCircularTypeList: function () {
      return this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.filter(item => item.value !== 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    seasonList: function () {
      return this.$store.state.incentiveGrant.commonObj.seasonList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    circularList: function () {
      const dataList = this.$store.state.incentiveGrant.commonObj.subsidyCircularList.filter(item => item.status === 1)
        return dataList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    instrumentList: function () {
      const dataList = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.status === 1)
        return dataList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    cropNameList: function () {
      const cropList = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
      return cropList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    },
    agMaterialTypeList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.filter(item => item.status === 1)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
        return { value: obj.value, text: obj.text_bn }
        } else {
        return { value: obj.value, text: obj.text_en }
        }
      })
    }
  },
  watch: {
    'formData.circular_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.formData.circular_type === 1) {
          this.totFarmerSHow = false
          this.cropShow = false
          this.matShow = false
          this.instrumentShow = true
        }
        if (this.formData.circular_type === 2 || this.formData.circular_type === 3) {
          this.totFarmerSHow = false
          this.instrumentShow = false
          this.cropShow = true
          this.matShow = true
          this.isCashAmountShow = true
            if (this.id && this.temp_circular_type === this.formData.circular_type) {
              this.formData.is_cash_amount = this.temp_is_cash_amount
            } else {
              this.formData.is_cash_amount = false
            }
        } else {
          this.formData.is_cash_amount = false
          this.isCashAmountShow = false
        }
        if (this.formData.circular_type === 4) {
          this.instrumentShow = false
          this.cropShow = false
          this.matShow = false
          this.totFarmerSHow = true
        }
        this.projectList = this.$store.state.incentiveGrant.commonObj.projectList.filter(projectList => projectList.circular_type === newVal)
      }
    },
    'formData.amount_per_farmer': function (newVal, oldVal) {
      if (newVal !== oldVal && this.formData.circular_type === 4) {
        this.formData.amount = this.getTotalAmountForFinancialAid()
      }
    }
  },
  methods: {
    handleFile (e) {
      const selectedPhoto = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        if (this.id) {
          this.formData.edit_attachment = event.target.result
        } else {
          this.formData.attachment = event.target.result
        }
      }
      reader.readAsDataURL(selectedPhoto)
    },
    getMaterialList (index) {
      const materialTypeId = this.formData.material_details[index].material_type_id
      const materials = this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(matType => matType.material_type_id === materialTypeId)
      const tmpMaterialList = materials.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      this.formData.material_details[index].materialList = tmpMaterialList
      const tmp = this.formData.material_details
      this.formData.material_details = []
      this.formData.material_details = tmp
    },
    getCircularData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      const tmpMaterialList = tmpData.material_details.map((item, key) => {
        const mats = this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(matType => matType.material_type_id === item.material_type_id)
        const tmpMaterials = mats.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
        return Object.assign(item, { materialList: tmpMaterials })
      })
      // tmpData.is_cash_amount = tmpData.is_cash_amount ? true : false
      const finalMaterials = Object.assign(tmpData, tmpMaterialList)
      return JSON.parse(JSON.stringify(finalMaterials))
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${circularInfoUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(incentiveGrantServiceBaseUrl, circularInfoStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false

      if (result.success) {
        this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: result.message,
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
    },
    addRoll () {
      const tampbpSetup = {
        crop_id: 0
      }
      const key1 = parseInt(this.formData.crop_details.length - 1)
      const item = this.formData.crop_details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
          this.formData.crop_details.push(tampbpSetup)
      }
    },
    remove (key) {
      this.formData.crop_details.splice(key, 1)
    },
    addMat () {
      const tampbpSetup = {
        material_type_id: 0,
        material_id: 0
      }
      const key1 = parseInt(this.formData.material_details.length - 1)
      const item = this.formData.material_details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
          this.formData.material_details.push(tampbpSetup)
      }
    },
    removeMat (key) {
      this.formData.material_details.splice(key, 1)
    },
    addInstrumnt () {
      const tampbpSetup = {
        instrument_id: 0
      }
      const key1 = parseInt(this.formData.instrument_details.length - 1)
      const item = this.formData.instrument_details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
          this.formData.instrument_details.push(tampbpSetup)
      }
    },
    removeInstrumnt (key) {
      this.formData.instrument_details.splice(key, 1)
    },
    getTotalAmountForFinancialAid () {
      return parseFloat(this.formData.total_farmer * this.formData.amount_per_farmer)
    }
  }
}
</script>
