// Agriculture MetarialSetup
export const aggriMaterialSetupList = '/dae/config/agricultural-material/list'
export const aggriMaterialSetupStore = '/dae/config/agricultural-material/store'
export const aggriMaterialSetupUpdate = '/dae/config/agricultural-material/update'
export const aggriMaterialSetupStatus = '/dae/config/agricultural-material/toggle-status'
export const aggriMaterialSetupDestroy = '/dae/config/agricultural-material/destroy'

// Agriculture Metarial Type Setup
export const aggriMaterialTypeSetupList = '/dae/config/agricultural-material-setup/list'
export const aggriMaterialTypeSetupStore = '/dae/config/agricultural-material-setup/store'
export const aggriMaterialTypeSetupUpdate = '/dae/config/agricultural-material-setup/update'
export const aggriMaterialTypeSetupStatus = '/dae/config/agricultural-material-setup/toggle-status'
export const aggriMaterialTypeSetupDestroy = '/dae/config/agricultural-material-setup/destroy'

// Crop Wise Agricultural Material Mapping
export const cropWiseAgMaMapList = '/dae/config/ms-mat-mapp/list'
export const cropWiseAgMaMapStore = '/dae/config/ms-mat-mapp/store'
export const cropWiseAgMaMapUpdate = '/dae/config/ms-mat-mapp/update'
export const cropWiseAgMaMapStatus = '/dae/config/ms-mat-mapp/toggle-status'
export const cropWiseAgMaMapDestroy = '/dae/config/ms-mat-mapp/destroy'

// Supplier Information Api Routes...
export const supplierInformationList = 'dae/config/supplier-information/list'
export const supplierInformationStore = 'dae/config/supplier-information/store'
export const supplierInformationUpdate = 'dae/config/supplier-information/update'
export const supplierInformationStatus = 'dae/config/supplier-information/toggle-status'
export const supplierInformationDestroy = 'dae/config/supplier-information/destroy'

// Subsidy Rate Setup
export const subsidyRateList = '/dae/config/subsidy-rate/list'
export const subsidyRateStore = '/dae/config/subsidy-rate/store'
export const subsidyRateUpdate = '/dae/config/subsidy-rate/update'
export const subsidyRateStatus = '/dae/config/subsidy-rate/toggle-status'
export const subsidyRateDestroy = '/dae/config/subsidy-rate/destroy'

// Agri Instrument
export const agriInstrumentList = '/dae/config/agri-instrument/list'
export const agriInstrumentStore = '/dae/config/agri-instrument/store'
export const agriInstrumentUpdate = '/dae/config/agri-instrument/update'
export const agriInstrumentStatus = '/dae/config/agri-instrument/toggle-status'

// Distribution Policy Api Routes...
export const distributionPolicyList = '/dae/config/distribution-policy/list'
export const distributionPolicyStore = '/dae/config/distribution-policy/store'
export const distributionPolicyUpdate = '/dae/config/distribution-policy/update'
export const distributionPolicyStatus = '/dae/config/distribution-policy/toggle-status'
export const distributionPolicyDestroy = '/dae/config/distribution-policy/destroy'
export const distriCirCropMaterial = '/dae/config/distribution-policy/circular-crop-material'

// Circular Info Api Routes...
export const circularInfoList = '/dae/config/circular/list'
export const circularInfoStore = '/dae/config/circular/store'
export const circularInfoUpdate = '/dae/config/circular/update'
export const circularInfoStatus = '/dae/config/circular/toggle-status'
export const circularInfoDestroy = '/dae/config/circular/destroy'
