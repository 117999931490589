
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_config.circular') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.fiscal_year')"
                      label-for="fiscal_year_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('dae_config.circular_type')"
                      label-for="circular_type"
                      >
                      <b-form-select
                          plain
                          v-model="search.circular_type"
                          :options="subsidyCircularTypeList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('dae_config.circular_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loadingState">
                        <b-row>
                          <b-col md="12" class="table-responsive">
                            <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                  {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(memo_no)="data">
                                  {{ data.item.memo_no }}
                                </template>
                                <template v-slot:cell(amount)="data">
                                  {{ $n(data.item.amount, { useGrouping: false }) }}
                                </template>
                                <template v-slot:cell(status)="data">
                                  <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                  <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                </template>
                                <template v-slot:cell(action)="data">
                                  <!-- <b-button class="mr-1" title="View Details" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                  <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                  <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                    <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                  </b-button> -->
                                  <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                                      <i class="fas fa-eye"></i>
                                  </a>
                                  <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                      <i class="ri-ball-pen-fill"></i>
                                  </a>
                                  <a href="javascript:" class="btn_table_action table_action_view" title="Status" v-if="data.item.status === 1" @click="remove(data.item)">
                                      <i class="fas fa-toggle-on"></i>
                                  </a>
                                  <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" v-if="data.item.status === 2" @click="remove(data.item)">
                                      <i class="fa fa-toggle-off"></i>
                                  </a>
                                </template>
                            </b-table>
                            <b-pagination
                              align="center"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total-rows="pagination.totalRows"
                              @input="searchData"
                            />
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="lg" :title="$t('dae_config.circular_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="dtlsItemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { circularInfoList, circularInfoStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form,
    Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        circular_type: 0,
        circular_type_list: [2, 3, 4]
      },
      item: '',
      dtlsItemId: ''
    }
  },
  computed: {
    fiscalList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
   subsidyCircularTypeList: function () {
      return this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.filter(item => item.value !== 1)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('dae_config.circular') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_config.circular') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('dae_grant_allocation_distribution.fiscal_year'), class: 'text-center' },
          { label: this.$t('dae_config.season_name'), class: 'text-center' },
          { label: this.$t('barc_config.circular_name'), class: 'text-center' },
          { label: this.$t('dae_config.circular_type'), class: 'text-center' },
          { label: this.$t('dae_subsidy.memo_no'), class: 'text-center' },
          { label: this.$t('dae_config.amount'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'season_name_bn' },
          { key: 'circular_name_bn' },
          { key: 'circular_type_name_bn' },
          { key: 'memo_no' },
          { key: 'amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'season_name' },
          { key: 'circular_name' },
          { key: 'circular_type_name' },
          { key: 'memo_no' },
          { key: 'amount' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
      this.dtlsItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, circularInfoStatus, item, 'incentive_grant', 'subsidyCircular')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, circularInfoList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const subsidyCircularTypeList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
      const seasonList = this.$store.state.incentiveGrant.commonObj.seasonList
      const projectList = this.$store.state.incentiveGrant.commonObj.projectList.filter(item => item.status === 1)

      return data.map(item => {
        const fiscalObject = fiscalYearList.find(fiscal => fiscal.value === item.fiscal_year_id)
        const circularTypeObject = subsidyCircularTypeList.find(typeItem => typeItem.value === item.circular_type)
        const seasonObject = seasonList.find(seasonItem => seasonItem.value === item.season_id)
        const projectObject = projectList.find(prj => prj.value === item.project_id)
        const fiscalyearData = {
          fiscal_year: fiscalObject !== 'undefined' ? fiscalObject.text_en : '',
          fiscal_year_bn: fiscalObject !== 'undefined' ? fiscalObject.text_bn : ''
        }
        const circularTypeData = {
          circular_type_name: circularTypeObject !== 'undefined' ? circularTypeObject.text_en : '',
          circular_type_name_bn: circularTypeObject !== 'undefined' ? circularTypeObject.text_bn : ''
        }
        const seasonData = {
          season_name: seasonObject !== undefined ? seasonObject.text_en : '',
          season_name_bn: seasonObject !== undefined ? seasonObject.text_bn : ''
        }
        const projectData = {
          project_name: projectObject !== 'undefined' ? projectObject.text_en : '',
          project_name_bn: projectObject !== 'undefined' ? projectObject.text_bn : ''
        }
        const otherData = {
          is_cash_amount: !!item.is_cash_amount
        }
        return Object.assign({}, item, fiscalyearData, circularTypeData, seasonData, projectData, otherData)
      })
    }
  }
}
</script>
